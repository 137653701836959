var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',{staticClass:"loader"}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"container"},[_c('h1',{staticClass:"textCenter"},[_vm._v("Profile Detail")]),_c('div',{staticClass:"col-xl-7 mx-auto px-0"},[_c('div',{staticClass:"row content-form-wrp"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"card bg-shadow"},[_c('div',{staticClass:"card-body"},[_c('form',{attrs:{"novalidate":""}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{},[_vm._v("Current Password")]),_c('div',{staticClass:"visible-position"},[_c('div',[(_vm.showOldPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.oldPassword),expression:"form.oldPassword"}],staticClass:"input",attrs:{"type":"text"},domProps:{"value":(_vm.form.oldPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "oldPassword", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.oldPassword),expression:"form.oldPassword"}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"password","formcontrolname":"Name","required":"","placeholder":"Enter old password"},domProps:{"value":(_vm.form.oldPassword)},on:{"blur":function($event){return _vm.$v.form.oldPassword.$touch()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "oldPassword", $event.target.value)}}})]),_c('div',{staticClass:"control visible-btn"},[_c('button',{staticClass:"button",on:{"click":function($event){$event.preventDefault();return _vm.toggleshowOldPassword.apply(null, arguments)}}},[_c('span',{staticClass:"icon is-small is-right"},[_c('i',{staticClass:"fas",class:{
                                  'fa-eye-slash': _vm.showOldPassword,
                                  'fa-eye': !_vm.showOldPassword,
                                }})])])])]),(_vm.$v.form.oldPassword.$error)?[(!_vm.$v.form.oldPassword.required)?_c('p',{staticClass:"errorMessage"},[_vm._v(" password is required* ")]):(!_vm.$v.form.oldPassword.minLength)?_c('p',{staticClass:"errorMessage"},[_vm._v(" password minimum length should be 8* ")]):(
                            !_vm.$v.form.oldPassword.containsNumber.required ||
                            !_vm.$v.form.oldPassword.containsUppercase.required ||
                            !_vm.$v.form.oldPassword.containsLowercase.required ||
                            !_vm.$v.form.oldPassword.containsSpecial.required
                          )?_c('p',{staticClass:"errorMessage"},[_vm._v(" password must contain lowercase,uppercase,digit and one special charater* ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{},[_vm._v("New Password")]),_c('div',{staticClass:"visible-position"},[_c('div',[(_vm.showNewPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newPassword),expression:"form.newPassword"}],staticClass:"input1",attrs:{"type":"text"},domProps:{"value":(_vm.form.newPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "newPassword", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newPassword),expression:"form.newPassword"}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"password","formcontrolname":"newPassword","required":"","placeholder":"Enter new password"},domProps:{"value":(_vm.form.newPassword)},on:{"blur":function($event){return _vm.$v.form.newPassword.$touch()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "newPassword", $event.target.value)}}})]),_c('div',{staticClass:"control visible-btn"},[_c('button',{staticClass:"button",on:{"click":function($event){$event.preventDefault();return _vm.toggleshowNewPassword.apply(null, arguments)}}},[_c('span',{staticClass:"icon is-small is-right"},[_c('i',{staticClass:"fas",class:{
                                  'fa-eye-slash': _vm.showNewPassword,
                                  'fa-eye': !_vm.showNewPassword,
                                }})])])])]),(_vm.$v.form.newPassword.$error)?[(!_vm.$v.form.newPassword.required)?_c('p',{staticClass:"errorMessage"},[_vm._v(" new password is required* ")]):(!_vm.$v.form.newPassword.minLength)?_c('p',{staticClass:"errorMessage"},[_vm._v(" new password minimum length should be 8* ")]):(
                            !_vm.$v.form.newPassword.containsNumber.required ||
                            !_vm.$v.form.newPassword.containsUppercase.required ||
                            !_vm.$v.form.newPassword.containsLowercase.required ||
                            !_vm.$v.form.newPassword.containsSpecial.required
                          )?_c('p',{staticClass:"errorMessage"},[_vm._v(" new password must contain lowercase,uppercase,digit and one special charater* ")]):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group"},[_c('label',{},[_vm._v("Confirm New Password")]),_c('div',{staticClass:"visible-position"},[_c('div',[(_vm.showNewConfirmPassword)?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newPassword),expression:"form.newPassword"}],staticClass:"input1",attrs:{"type":"text"},domProps:{"value":(_vm.form.newPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "newPassword", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.newConfirmPassword),expression:"form.newConfirmPassword"}],staticClass:"form-control",attrs:{"autocomplete":"off","type":"password","formcontrolname":"confirmnewPassword","required":"","placeholder":"Enter confirm new password"},domProps:{"value":(_vm.form.newConfirmPassword)},on:{"blur":function($event){return _vm.$v.form.newConfirmPassword.$touch()},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "newConfirmPassword", $event.target.value)}}})]),_c('div',{staticClass:"control visible-btn"},[_c('button',{staticClass:"button",on:{"click":function($event){$event.preventDefault();return _vm.toggleshowConfirmPassword.apply(null, arguments)}}},[_c('span',{staticClass:"icon is-small is-right"},[_c('i',{staticClass:"fas",class:{
                                  'fa-eye-slash': _vm.showNewConfirmPassword,
                                  'fa-eye': !_vm.showNewConfirmPassword,
                                }})])])])]),(_vm.$v.form.newConfirmPassword.$error)?[(!_vm.$v.form.newConfirmPassword.required)?_c('p',{staticClass:"errorMessage"},[_vm._v(" confirmPassword is required* ")]):(
                            _vm.$v.form.newPassword != _vm.$v.form.newConfirmPassword
                          )?_c('p',{staticClass:"errorMessage"},[_vm._v(" confirm password not match with new password* ")]):_vm._e()]:_vm._e()],2)])]),_c('div',{},[_c('button',{staticClass:"btn btn-primary br-5",attrs:{"type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.changePassword.apply(null, arguments)}}},[_vm._v(" Update Password Details ")])])])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }