<template>
  <div>
    <div class="loader" v-if="loading"></div>
    <div class="container" v-show="!loading">
      <h1 class="textCenter">Profile Detail</h1>
      <div class="col-xl-7 mx-auto px-0">
        <div class="row content-form-wrp">
          <div class="col-lg-12">
            <div class="card bg-shadow">
              <div class="card-body">
                <form novalidate class="">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="">Current Password</label>
                        <div class="visible-position">
                          <div>
                            <input
                              v-if="showOldPassword"
                              type="text"
                              class="input"
                              v-model="form.oldPassword"
                            />
                            <input
                              v-else
                              autocomplete="off"
                              type="password"
                              formcontrolname="Name"
                              required
                              class="form-control"
                              placeholder="Enter old password"
                              v-model="form.oldPassword"
                              @blur="$v.form.oldPassword.$touch()"
                            />
                          </div>
                          <div class="control visible-btn">
                            <button
                              class="button"
                              @click.prevent="toggleshowOldPassword"
                            >
                              <span class="icon is-small is-right">
                                <i
                                  class="fas"
                                  :class="{
                                    'fa-eye-slash': showOldPassword,
                                    'fa-eye': !showOldPassword,
                                  }"
                                ></i>
                              </span>
                            </button>
                          </div>
                        </div>
                        <template v-if="$v.form.oldPassword.$error">
                          <p
                            class="errorMessage"
                            v-if="!$v.form.oldPassword.required"
                          >
                            password is required*
                          </p>
                          <p
                            class="errorMessage"
                            v-else-if="!$v.form.oldPassword.minLength"
                          >
                            password minimum length should be 8*
                          </p>
                          <p
                            class="errorMessage"
                            v-else-if="
                              !$v.form.oldPassword.containsNumber.required ||
                              !$v.form.oldPassword.containsUppercase.required ||
                              !$v.form.oldPassword.containsLowercase.required ||
                              !$v.form.oldPassword.containsSpecial.required
                            "
                          >
                            password must contain lowercase,uppercase,digit and
                            one special charater*
                          </p></template
                        >
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="">New Password</label>
                        <div class="visible-position">
                          <div>
                            <input
                              v-if="showNewPassword"
                              type="text"
                              class="input1"
                              v-model="form.newPassword"
                            />
                            <input
                              v-else
                              autocomplete="off"
                              type="password"
                              formcontrolname="newPassword"
                              required
                              class="form-control"
                              placeholder="Enter new password"
                              v-model="form.newPassword"
                              @blur="$v.form.newPassword.$touch()"
                            />
                          </div>
                          <div class="control visible-btn">
                            <button
                              class="button"
                              @click.prevent="toggleshowNewPassword"
                            >
                              <span class="icon is-small is-right">
                                <i
                                  class="fas"
                                  :class="{
                                    'fa-eye-slash': showNewPassword,
                                    'fa-eye': !showNewPassword,
                                  }"
                                ></i>
                              </span>
                            </button>
                          </div>
                        </div>
                        <!----><!---->
                        <template v-if="$v.form.newPassword.$error">
                          <p
                            class="errorMessage"
                            v-if="!$v.form.newPassword.required"
                          >
                            new password is required*
                          </p>
                          <p
                            class="errorMessage"
                            v-else-if="!$v.form.newPassword.minLength"
                          >
                            new password minimum length should be 8*
                          </p>
                          <p
                            class="errorMessage"
                            v-else-if="
                              !$v.form.newPassword.containsNumber.required ||
                              !$v.form.newPassword.containsUppercase.required ||
                              !$v.form.newPassword.containsLowercase.required ||
                              !$v.form.newPassword.containsSpecial.required
                            "
                          >
                            new password must contain lowercase,uppercase,digit
                            and one special charater*
                          </p>
                        </template>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="">Confirm New Password</label>
                        <div class="visible-position">
                          <div>
                            <input
                              v-if="showNewConfirmPassword"
                              type="text"
                              class="input1"
                              v-model="form.newPassword"
                            />
                            <input
                              v-else
                              autocomplete="off"
                              type="password"
                              formcontrolname="confirmnewPassword"
                              required
                              class="form-control"
                              placeholder="Enter confirm new password"
                              v-model="form.newConfirmPassword"
                              @blur="$v.form.newConfirmPassword.$touch()"
                            />
                          </div>
                          <div class="control visible-btn">
                            <button
                              class="button"
                              @click.prevent="toggleshowConfirmPassword"
                            >
                              <span class="icon is-small is-right">
                                <i
                                  class="fas"
                                  :class="{
                                    'fa-eye-slash': showNewConfirmPassword,
                                    'fa-eye': !showNewConfirmPassword,
                                  }"
                                ></i>
                              </span>
                            </button>
                          </div>
                        </div>
                        <template v-if="$v.form.newConfirmPassword.$error">
                          <p
                            class="errorMessage"
                            v-if="!$v.form.newConfirmPassword.required"
                          >
                            confirmPassword is required*
                          </p>
                          <!-- <p
                            class="errorMessage"
                            v-else-if="!$v.form.newConfirmPassword.minLength"
                          >
                            confirm password minimum length should be 8*
                          </p> -->
                          <p
                            class="errorMessage"
                            v-else-if="
                              $v.form.newPassword != $v.form.newConfirmPassword
                            "
                          >
                            confirm password not match with new password*
                          </p>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <button
                      @click.prevent="changePassword"
                      type="submit"
                      class="btn btn-primary br-5"
                    >
                      Update Password Details
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  data() {
    return {
      loading: true,
      showOldPassword: false,
      showNewPassword: false,
      showNewConfirmPassword: false,
      form: {
        oldPassword: "",
        newPassword: "",
        newConfirmPassword: "",
      },
    };
  },
  validations: {
    form: {
      oldPassword: {
        required,
        minLength: minLength(8),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function (value) {
          return /[#?!@$%^&*-]/.test(value);
        },
      },
      newPassword: {
        required,
        minLength: minLength(8),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function (value) {
          return /[#?!@$%^&*-]/.test(value);
        },
      },
      newConfirmPassword: {
        required,
        minlength: minLength(8),
        sameAs: sameAs(function () {
          return this.form.newPassword;
        }),
      },
    },
  },
  computed: {
    buttonLabelForOldPw() {
      return this.showOldPassword ? "Hide" : "Show";
    },
    buttonLabelForNewPw() {
      return this.showNewPassword ? "Hide" : "Show";
    },
    buttonLabelForNewCnfmPw() {
      return this.showNewConfirmPassword ? "Hide" : "Show";
    },
  },
  methods: {
    changePassword() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        alert("You're good to go.");
        console.log("Form submiited successully");
        console.log(this.information);
      }
    },
    toggleshowOldPassword() {
      this.showOldPassword = !this.showOldPassword;
    },
    toggleshowNewPassword() {
      this.showNewPassword = !this.showNewPassword;
    },
    toggleshowConfirmPassword() {
      this.showNewConfirmPassword = !this.showNewConfirmPassword;
    },
  },
  created() {
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
.h-100 {
  height: 100%;
}

.propic {
  @media (max-width: 991px) {
    margin-bottom: 20px;
  }
}
.bg-shadow {
  box-shadow: 0 0 10px #00000029;
  border-radius: 10px;
  @media (min-width: 1921px) {
    border-radius: 0.521vw;
  }
}
.card {
  background-color: #fff;
  padding: 15px;
  @media (min-width: 1921px) {
    padding: 0.781vw;
  }
  .form-group {
    margin-bottom: 15px;
    @media (min-width: 1921px) {
      margin-bottom: 0.781vw;
    }
    input[type="text"],
    input[type="password"],
    input[type="email"] {
      height: 40px;
      border: 1px solid #d4d4d4;
      border-radius: 5px;
      box-shadow: unset;
      font-size: 14px;
      padding: 0 15px;
      width: 100%;
      @media (min-width: 1921px) {
        height: 2.083vw;
        padding: 0 0.781vw;
        font-size: 0.729vw;
      }
    }
    label {
      margin-bottom: 5px;
      display: block;
      @media (min-width: 1921px) {
        margin-bottom: 0.26vw;
        font-size: 0.833vw;
      }
    }
  }
  button {
    background-color: #2996b8;
    color: #fff;
    box-shadow: none;
  }

  .card-header {
    text-align: center;
  }
  .round-container {
    max-width: 8.958vw;
    width: 100%;
    height: 8.958vw;
    border-radius: 50%;
    margin: auto;
    overflow: hidden;
    position: relative;
    border: 1px solid #d1d5da;
    @media (max-width: 1199px) {
      max-width: 105px;
      height: 105px;
      margin-bottom: 25px;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .errorMessage {
    color: red;
  }
  .upload-component {
    position: relative;
    text-align: center;
    input[type="file"] {
      position: absolute;
      opacity: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      left: 0;
      top: 0;
    }
  }
}
.visible-position {
  position: relative;
}
.visible-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  button {
    background-color: transparent;
    border: 0;
    box-shadow: none;
    color: #000;
  }
}
</style>
